import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import {
  getUploadedImgSheetName,
  isImageFileUploadTask,
} from "@/modes/create-area-mode/create-area-utils";
import { selectBackgroundTasksBasedOnState } from "@/store/background-tasks/background-tasks-selector";
import { setActiveCad } from "@/store/cad/cad-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { AppStore } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  BackgroundTask,
  BackgroundTaskTypes,
  getErrorCodeAndTaskName,
} from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import {
  CreateDialogProps,
  OpenToastOptions,
  addIElements,
  fetchProjectIElements,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  useDialog,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElementTypeHint,
  isGeoReferencedElement,
  isIElementModel3dStream,
  isIElementPointCloudStream,
  isIElementSectionDataSession,
  isValidPose,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  ProgressApiSupportedTaskTypes,
  ProjectApi,
  taskErrorToUserMessage,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { selectDisableCaptureTreeAlignment } from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

/** Params to pass to a action function triggered when a task changes state */
type TaskActionParams = {
  /** The task that changed state */
  task: BackgroundTask;

  /** A function to open a toast */
  openToast(options: OpenToastOptions): unknown;

  /** A function to open a modal dialog */
  createDialog(options: CreateDialogProps): unknown;

  /** The redux store of the application */
  appStore: AppStore;

  /** Project API */
  projectApi: ProjectApi;
};

/** All the task actions possible for task state changes */
type TaskActions = {
  /** Action triggered when the task complete successfully */
  completed(params: TaskActionParams): Promise<void>;

  /** Action triggered when the task fail */
  failed(params: TaskActionParams): Promise<void>;
};

const pcConversionAction: TaskActions = {
  // eslint-disable-next-line require-await -- FIXME
  async completed({ task, openToast, appStore, projectApi }) {
    assert(
      task.type === ProgressApiSupportedTaskTypes.pointCloudLazToPotree ||
        task.type === ProgressApiSupportedTaskTypes.pointCloudToPotree,
    );

    const appState = appStore.getState();

    const element = selectIElement(task.iElementId)(appState);

    const cloudSection = selectAncestor(
      element,
      isIElementSectionDataSession,
    )(appState);

    const isGeoReferenced = isGeoReferencedElement(cloudSection);

    const isFromCaptureTree = selectAncestor(
      element,
      (el) => el.typeHint === IElementTypeHint.captureTree,
    )(appState);

    const disableAlignment = selectDisableCaptureTreeAlignment(appState);

    // If the element is in the capture tree, retrieve the updated parent
    // and all the sibilings
    if (isFromCaptureTree && element?.parentId) {
      const parent = (
        await projectApi.getAllIElements({
          ids: [element.parentId],
        })
      ).at(0);
      if (parent?.childrenIds) {
        const elements = await projectApi.getAllIElements({
          ids: parent.childrenIds,
        });
        appStore.dispatch(addIElements([parent, ...elements]));
      }
    }

    openToast({
      title: "Import Successful",
      message: `The import of ${
        element?.name ?? "the point cloud"
      } was completed successfully.`,
      variant: "success",
      persist: true,
      action:
        disableAlignment ||
        isValidPose(cloudSection?.pose) ||
        isGeoReferenced ||
        isFromCaptureTree
          ? undefined
          : {
              label: "Align",
              onClicked: () => {
                if (!task.iElementId) return;

                const appState = appStore.getState();
                const taskElement = selectIElement(task.iElementId)(appState);
                if (!taskElement) return;

                const laserScanSection = selectAncestor(
                  taskElement,
                  isIElementSectionDataSession,
                )(appState);
                if (!laserScanSection) return;

                const activeArea = selectActiveArea(laserScanSection)(appState);
                if (!activeArea) return;

                const pointCloud = selectChildDepthFirst(
                  laserScanSection,
                  isIElementPointCloudStream,
                )(appState);

                const dataSession = selectAncestor(
                  pointCloud,
                  isIElementSectionDataSession,
                )(appState);

                if (!pointCloud || !dataSession) return;

                openAlignmentWizard({
                  elementIdToAlign: dataSession.id,
                  dispatch: appStore.dispatch,
                });
              },
            },
    });
  },

  // eslint-disable-next-line require-await -- FIXME
  async failed({ task, openToast, appStore }) {
    assert(
      task.type === ProgressApiSupportedTaskTypes.pointCloudLazToPotree ||
        task.type === ProgressApiSupportedTaskTypes.pointCloudToPotree,
    );

    const element = task.iElementId
      ? selectIElement(task.iElementId)(appStore.getState())
      : undefined;

    const { taskName, errorCode } = getErrorCodeAndTaskName(
      task,
      element?.name,
    );

    openToast({
      title: "File Conversion Failed",
      message: taskErrorToUserMessage(taskName, errorCode),
      variant: "error",
    });
  },
};

/**
 * Map to pair each background task with the actions needed when the state changes
 * to completed or failed.
 * In future we could move these function to a specific file for each task and just reference
 * them here
 */
const TASK_ACTIONS: Partial<Record<BackgroundTaskTypes, Partial<TaskActions>>> =
  {
    // Actions for the FileUpload task state changes
    FileUpload: {
      // eslint-disable-next-line require-await -- FIXME
      async completed({ task, openToast, appStore }) {
        assert(task.type === "FileUpload");

        if (task.silent) return;

        // Custom toast for tasks that have uploaded a file for an ImgSheet
        if (isImageFileUploadTask(task)) {
          const name = getUploadedImgSheetName(task, appStore);
          openToast({
            title: `${name ?? "Image sheet"} uploaded successfully`,
            message: "",
          });
          return;
        }

        openToast({
          title: "Upload successful, now processing.",
          message: "You can close the browser during this process",
          variant: "success",
        });
      },

      // eslint-disable-next-line require-await -- FIXME
      async failed({ task, openToast }) {
        assert(task.type === "FileUpload");

        if (task.silent) return;

        openToast({
          title: "File Upload",
          message: `File ${task.metadata.filename} upload failed`,
          variant: "error",
        });
      },
    },
    // Actions for the PointCloudLazToPotree task state changes
    PointCloudToPotree: pcConversionAction,
    // TODO: Remove after backend update: https://faro01.atlassian.net/browse/SWEB-1980
    PointCloudLazToPotree: pcConversionAction,

    PointCloudExport: {
      // eslint-disable-next-line require-await -- FIXME
      async completed({ task, openToast }) {
        if (
          task.type !== ProgressApiSupportedTaskTypes.pointCloudExport ||
          !task.metadata.downloadUrl
        ) {
          return;
        }
        downloadFile(task.metadata.downloadUrl);
        openToast({
          title: "Point cloud export",
          message: "The download has started",
          variant: "success",
        });
      },
      // eslint-disable-next-line require-await -- FIXME
      async failed({ task, openToast }) {
        assert(task.type === ProgressApiSupportedTaskTypes.pointCloudExport);
        openToast({
          title: "Point cloud export",
          message: "Export failed",
          variant: "error",
        });
      },
    },
    BimModelImport: {
      // eslint-disable-next-line require-await -- FIXME
      async completed({ task, openToast, appStore }) {
        assert(task.type === ProgressApiSupportedTaskTypes.bimModelImport);

        const element = task.iElementId
          ? selectIElement(task.iElementId)(appStore.getState())
          : undefined;

        openToast({
          title: "Import Successful",
          message: `The import of ${
            element?.name ?? "the 3D Model"
          } was completed successfully`,
          variant: "success",
          persist: true,
        });
      },

      async failed({ task, openToast, appStore, projectApi }) {
        assert(task.type === ProgressApiSupportedTaskTypes.bimModelImport);

        const element = task.iElementId
          ? selectIElement(task.iElementId)(appStore.getState())
          : undefined;

        if (element) {
          await appStore.dispatch(
            fetchProjectIElements({
              // eslint-disable-next-line require-await -- FIXME
              fetcher: async () =>
                projectApi.getAllIElements({
                  ancestorIds: [element.id],
                }),
            }),
          );

          const newElement = selectIElement(element.id)(appStore.getState());

          const bimModelStream = selectChildDepthFirst(
            newElement,
            isIElementModel3dStream,
          )(appStore.getState());

          if (bimModelStream) {
            appStore.dispatch(setActiveCad(bimModelStream.id));
          }
        }

        const { taskName, errorCode } = getErrorCodeAndTaskName(
          task,
          element?.name,
        );

        openToast({
          title: "File Conversion Failed",
          message: taskErrorToUserMessage(taskName, errorCode),
          variant: "error",
        });
      },
    },

    // Actions for multi cloud/data set registration
    // The messages will be changed in future design iterations
    RegisterMultiCloudDataSet: {
      // eslint-disable-next-line require-await -- FIXME
      async completed({ openToast }) {
        openToast({
          title: "Data Set Registration Completed",
          message: "The point clouds in the data set have been aligned.",
          variant: "success",
        });
      },

      // eslint-disable-next-line require-await -- FIXME
      async failed({ openToast }) {
        openToast({
          title: "Data Set Registration Failed",
          message: "There was an error while trying to align the point clouds.",
          variant: "error",
        });
      },
    },
  };

/**
 * @returns A container that will track tasks and execute action when their state change
 *
 * For now what action to do for each task is statically encoded in this component, so each task type
 * will always have the same action.
 * This is the simplest design and is ok for our current needs.
 * If we will ever need custom actions for the same task then we can refactor this component to allow
 * components to register actions using a Context to track the actions and a custom hook
 */
export function BackgroundTaskNotifier(): JSX.Element | null {
  const { openToast } = useToast();
  const { createDialog } = useDialog();
  const appStore = useAppStore();

  const projectApi = useCurrentProjectApiClient();

  /** Keeping track of which tasks a notification was already shown */
  const [handledTasks, setHandledTasks] = useState<GUID[]>([]);

  // Show toasts for successful tasks
  const succeededBackgroundTasks = useAppSelector(
    selectBackgroundTasksBasedOnState(BackgroundTaskState.succeeded),
  );
  useEffect(() => {
    for (const succeededBackgroundTask of succeededBackgroundTasks) {
      // Avoid showing a notification for the same task multiple times
      if (handledTasks.includes(succeededBackgroundTask.id)) continue;

      const action = TASK_ACTIONS[succeededBackgroundTask.type]?.completed;
      if (!action) continue;

      action({
        appStore,
        createDialog,
        openToast,
        task: succeededBackgroundTask,
        projectApi,
      });

      setHandledTasks((handledTasks) => [
        ...handledTasks,
        succeededBackgroundTask.id,
      ]);
    }

    // Not adding handledTasks as dep because there is no need to run this effect in case the other effect updates the state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succeededBackgroundTasks]);

  // Show toasts for failed tasks
  const failedBackgroundTasks = useAppSelector(
    selectBackgroundTasksBasedOnState(BackgroundTaskState.failed),
  );
  useEffect(() => {
    for (const failedBackgroundTask of failedBackgroundTasks) {
      // Avoid showing a notification for the same task multiple times
      if (handledTasks.includes(failedBackgroundTask.id)) continue;

      const action = TASK_ACTIONS[failedBackgroundTask.type]?.failed;
      if (!action) continue;

      action({
        appStore,
        createDialog,
        openToast,
        task: failedBackgroundTask,
        projectApi,
      });

      setHandledTasks((handledTasks) => [
        ...handledTasks,
        failedBackgroundTask.id,
      ]);
    }

    // Not adding handledTasks as dep because there is no need to run this effect in case the other effect updates the state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedBackgroundTasks]);

  return null;
}
